<template>
  <div id="patient-medications">
    <CardHeader
      title="Medicamentos cadastrados"
      :showPeriod="false"
      :options="treatments"
      @changeOption="changeTreatment"
      class="mb-3"
    >
      <v-generic-filter
        :options="allMedications"
        @changeFilter="changeFilter($event, 'medication_id')"
        class="generic-filter first-filter"
      />

      <v-generic-filter
        :options="medicationTypes"
        @changeFilter="changeFilter($event, 'medicationType')"
        class="generic-filter"
      />
    </CardHeader>

    <b-row>
      <b-col cols="12">
        <perfect-scrollbar
          ref="scroll"
          v-if="filteredPatientMedications.length"
        >
          <b-row cols="2" class="card-container">
            <b-col
              cols="6"
              v-for="patientMedication in filteredPatientMedications"
              :key="patientMedication.id"
            >
              <v-patient-medication-card
                :medicine="patientMedication.medication?.medicine"
                :status="patientMedication.status"
                :eyes="patientMedication.eyes"
                :timesADay="patientMedication.times_a_day"
                :disease="patientMedication.userTreatment.disease"
                :medicationType="patientMedication.medication?.medication_type"
                class="ml-2"
              />
            </b-col>
          </b-row>
        </perfect-scrollbar>

        <b-row v-else class="justify-content-center">
          <v-no-filters-results message="Nenhum resultado encontrado" />
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CardHeader from '@/layouts/InvoicingTiss/Dashboard/CardHeader'
import GenericMultiselectFilter from '@/components/General/GenericMultiselectFilter'
import PatientMedicationCard from '@/components/Glauco/Dashboard/Patient/PatientMedicationCard'
import NoFiltersResults from '@/components/General/noFilterResults'

export default {
  name: 'PatientMedications',
  props: {
    treatments: {
      type: Array,
      required: true
    }
  },
  components: {
    CardHeader,
    'v-patient-medication-card': PatientMedicationCard,
    'v-generic-filter': GenericMultiselectFilter,
    'v-no-filters-results': NoFiltersResults
  },
  data() {
    return {
      patientMedications: [],
      patientId: this.$route.params.patientId,
      medicationTypes: [
        { label: 'Todos os tipos', value: null },
        { label: 'Colírios', value: 'eye_drop' },
        { label: 'Injeção', value: 'injection' },
        { label: 'Laser', value: 'lase' },
        { label: 'Via oral', value: 'oral' }
      ],
      allMedications: [],
      filters: {
        treatment: null,
        medicationType: null,
        medication_id: null
      }
    }
  },
  computed: {
    filteredPatientMedications() {
      if (Object.values(this.filters).every(filter => !filter)) {
        return this.patientMedications
      }

      return this.patientMedications.filter(patientMedication => {
        if (
          this.filters.medication_id &&
          patientMedication.medication_id !== this.filters.medication_id
        ) {
          return false
        }

        if (
          this.filters.treatment &&
          patientMedication.treatment !== this.filters.treatment
        ) {
          return false
        }

        if (
          this.filters.medicationType &&
          patientMedication.medication?.medication_type !==
            this.filters.medicationType
        ) {
          return false
        }
        return true
      })
    }
  },
  async created() {
    await this.getPatientMedications()
  },
  methods: {
    async getPatientMedications() {
      try {
        const response = await this.api.getPatientMedications(this.patientId)
        this.patientMedications = response.data.map(patientMedication => {
          return {
            ...patientMedication,
            treatment: patientMedication.userTreatment.disease,
            medicationType: patientMedication.medication.medication_type
          }
        })
        this.fillMedications()
      } catch (error) {
        this.$toast.error('Erro ao buscar medicamentos do paciente')
      }
    },
    fillMedications() {
      this.allMedications = this.patientMedications.map(patientMedication => {
        return {
          label: patientMedication.medication.medicine,
          value: patientMedication.medication.id
        }
      })

      this.allMedications.unshift({
        label: 'Todos os medicamentos',
        value: null
      })

      this.allMedications = this.allMedications.filter(
        (medication, index, self) =>
          index === self.findIndex(m => m.value === medication.value)
      )
    },
    changeTreatment(option) {
      if (!option.value) {
        this.filters.treatment = null
        return
      }
      this.filters.treatment = option.label
    },
    changeFilter(option, filter) {
      this.filters[filter] = option.value
    }
  }
}
</script>

<style lang="scss">
#patient-medications {
  .title {
    font-weight: 700;
    font-family: 'Red Hat Display';
    font-size: min(1.2vw, 16px);
  }

  padding: 30px 24px;
  margin: 0 auto;
  background-color: var(--neutral-000);
  border-radius: 8px;
  margin-top: 2%;
  margin-bottom: 2%;

  .generic-filter {
    &.first-filter {
      margin-left: auto !important;
    }
    max-width: 30% !important;
  }

  .card-container {
    max-height: 420px;
  }
}
</style>
